import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Smarthome/MQTT/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import PrimaryBox from 'components/Web_User_Interface/1440p_Series/Smarthome/MQTT/PrimaryBox';
import DangerBox from 'components/Web_User_Interface/1440p_Series/Smarthome/MQTT/DangerBox';
import MqttTableBroker from 'components/Web_User_Interface/1440p_Series/Smarthome/MQTT/MqttTableBroker';
import MqttTableClient from 'components/Web_User_Interface/1440p_Series/Smarthome/MQTT/MqttTableClient';
import TimeLine from 'components/Products/CommentList/MqttUpdatesList';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // INSTAR MQTT Broker",
  "path": "/Web_User_Interface/1440p_Series/Network/MQTT/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Configure the INSTAR MQTT broker - activate the server, or switch to an external broker. You can also configure the authentication and add your personal SSL certificate.",
  "image": "./WebUI_1440p_SearchThumb_Smarthome_MQTT.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Smarthome_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Smarthome Menu // INSTAR MQTT Broker' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='Configure the INSTAR MQTT broker - activate the server, or switch to an external broker. You can also configure the authentication and add your personal SSL certificate.' image='/images/Search/WebUI_1440p_SearchThumb_Smarthome_MQTT.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Smarthome_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Netzwerk/MQTT/' locationFR='/fr/Web_User_Interface/1440p_Series/Network/MQTT/' crumbLabel="MQTT Broker" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-menu",
        "aria-label": "smarthome menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/11af072094ac18121000bb75a8cca880/8dd93/1440p_Settings_Features_MQTT_Broker.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC0ElEQVQ4y2XT3W/bVBzG8d5vFTDIpLGUvDhtbKdUzdpmTWPSOHHit9iJX1unSUukaUjL4AYVwaRxwYbEBRJcIJC2OyQk/ssvsqMmHbt4dORz8dFzfud4Y3d3l1qtlkWWZURRRBAEOrOXmN/+jbp4g/r87btZvKX3/A3Bi3+oqyGF/AO2d6oMdJ2NmiyvMEmSsuRyn9CPvuLy+jfCxWvCZ68JFz/fSrr3isvv/+SRovPB5h0+vncPoVhgQ74Fpu1ScHPzLu3WY2ZJSDi2icZDfNdkPDTwHCNbx0OdIJlz8GifbaFAtZLn4f27azCF1uAmiqIQn52TTC64+nLOdHZJEEb4QUgYxfh+gB/EjIcq8wsNrdugkM+9C0qSmKH5BzksUydJJiRJsorneTiOg23bGIaBPXSY+AZTe59Bu85Qb73fMP2ulvNYus50NiOKInzfzzDXdTNI0zR6vS66YRKPBvi9Gk6vzjw5/X/DJVqrbuO5dtYwBVMsCAJM08xGcXJyQrPZpNPpEo8HDBpFYrvOj7PuGrxBq1WRxuE+URgQx3GWtGEay7Jot9t8oSi0Wieo3R7a6TGPpfv88FTnlyc2G1mjWzPcEWVatU8JLJWL2RWTyWQFjkYjTMNAN6ysbRjG9DsNlHqOP156fBe2lmD6uJfHTdEdHgom3cEVkyRaNUyPHYYh5+dnnE/n2e0nyQWeeYyrCXwzVXCPK0twPT8JWdqmGfyEc/Y1s0lIGAQZlqLpDWv9Pr1ul57awdANBmoDpSFQEbb46MM7S/AmN6hQLtAf9HGDBNv18VMwCHBsC62joKqntHpdNMvi6OiIQuEzyuUSxfRPWUPiao75QpnEUfn3hc2vC4ux5+ONHPzZM4Lrv5jPr/ldt3jlehweHrK1tUWpVMryPiiKlEplPperdI5kmnVp+QrS17BXp3bQYm/vgKYo0ZBlKpUKxWIxw9L1P9+v6NfkI5aOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/11af072094ac18121000bb75a8cca880/e4706/1440p_Settings_Features_MQTT_Broker.avif 230w", "/en/static/11af072094ac18121000bb75a8cca880/d1af7/1440p_Settings_Features_MQTT_Broker.avif 460w", "/en/static/11af072094ac18121000bb75a8cca880/7f308/1440p_Settings_Features_MQTT_Broker.avif 920w", "/en/static/11af072094ac18121000bb75a8cca880/19805/1440p_Settings_Features_MQTT_Broker.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/11af072094ac18121000bb75a8cca880/a0b58/1440p_Settings_Features_MQTT_Broker.webp 230w", "/en/static/11af072094ac18121000bb75a8cca880/bc10c/1440p_Settings_Features_MQTT_Broker.webp 460w", "/en/static/11af072094ac18121000bb75a8cca880/966d8/1440p_Settings_Features_MQTT_Broker.webp 920w", "/en/static/11af072094ac18121000bb75a8cca880/61eb3/1440p_Settings_Features_MQTT_Broker.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/11af072094ac18121000bb75a8cca880/81c8e/1440p_Settings_Features_MQTT_Broker.png 230w", "/en/static/11af072094ac18121000bb75a8cca880/08a84/1440p_Settings_Features_MQTT_Broker.png 460w", "/en/static/11af072094ac18121000bb75a8cca880/c0255/1440p_Settings_Features_MQTT_Broker.png 920w", "/en/static/11af072094ac18121000bb75a8cca880/8dd93/1440p_Settings_Features_MQTT_Broker.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/11af072094ac18121000bb75a8cca880/c0255/1440p_Settings_Features_MQTT_Broker.png",
              "alt": "Web User Interface - 1440p Series - Network MQTT",
              "title": "Web User Interface - 1440p Series - Network MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttTableBroker mdxType="MqttTableBroker" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01efa6ac9b42703ec27c8d667097c6d4/8dd93/1440p_Settings_Features_MQTT_Client.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVQ4y22SS2/TQBRGs6cVLwVR5eE4bptXhRoKAWrnWeM0j6a2x3ZJmgKVAKG2sKkAgQQLChJCLBBiBTskfudB45AYKIujO/ZIZ765c2MrKyuUSqWQYrFIPp9H0zQae6/pHv+gefiN5tH3U2wcfUO8+km56aEkF1hazmG128RKxeJMVigUQuLxOKb3iPHxJ8TBCd7hu9+8j9YHJ+w9+8xV3eLs/BwXL1xAyyjEin8IZTopnJ+fo7peYRg4uIPODLu/ibMVrUUwZK18hSVNIbeYJHFpLhJKUSScRzcM/GAHV3hhFZ5PEOxw99597t3fZzgc4bgCu99gf9fEbFVQkvG/hYVCPpQmF+J0O23G4zG+7+M4Dq7r0uv1qNVqGIaBrq/TaLYYiU3GvVWsWpl+Wz+dUH7nsik2rduMRiOCIMDzPIQQdLtdqtXqb6FOa8Mk2LZwWyW2NsrsD+v/JpxIS7kl7K0ud4bDWUIplAkbjQb1ej1MumFaBLaFVVEIemXe7LUi4VSay+WpXFtFuC6uK8KrTpFiiW3bIbKHZv0GNwqXePmozYcHPWJhoj96uJwvopcS2O0qjoj6N63ykeRBwnHwhI/VqGCU43x97fDc0ydCOdyT60rpMgmtQ8Mc44ltbNuZJXO2B+G4bA/6dBybrnzlzk0GpsbTscHg5uJEGPWvQLGwxLp4Sz94QuDZ+H4wQTgEdx+z+/wL+w9f8NEWnIgd2s0KxnWNRS3F+XNnJsIpU6mmKVQqa7MRkS9bNXSqTYta7w4ts49/ax1hVMPbZTIKWTUT1lgkiqqqZkmn06dJJUklLpNKJlhIp0MURSGTkTIVVVX/L8xms+Hmfwn3smRVNWTyPxMixb8A01revVl4YWEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01efa6ac9b42703ec27c8d667097c6d4/e4706/1440p_Settings_Features_MQTT_Client.avif 230w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/d1af7/1440p_Settings_Features_MQTT_Client.avif 460w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/7f308/1440p_Settings_Features_MQTT_Client.avif 920w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/19805/1440p_Settings_Features_MQTT_Client.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01efa6ac9b42703ec27c8d667097c6d4/a0b58/1440p_Settings_Features_MQTT_Client.webp 230w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/bc10c/1440p_Settings_Features_MQTT_Client.webp 460w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/966d8/1440p_Settings_Features_MQTT_Client.webp 920w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/61eb3/1440p_Settings_Features_MQTT_Client.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01efa6ac9b42703ec27c8d667097c6d4/81c8e/1440p_Settings_Features_MQTT_Client.png 230w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/08a84/1440p_Settings_Features_MQTT_Client.png 460w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/c0255/1440p_Settings_Features_MQTT_Client.png 920w", "/en/static/01efa6ac9b42703ec27c8d667097c6d4/8dd93/1440p_Settings_Features_MQTT_Client.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01efa6ac9b42703ec27c8d667097c6d4/c0255/1440p_Settings_Features_MQTT_Client.png",
              "alt": "Web User Interface - 1440p Series - Network MQTT",
              "title": "Web User Interface - 1440p Series - Network MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <MqttTableClient mdxType="MqttTableClient" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <h3 {...{
      "id": "how-to-talk-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-talk-mqtt",
        "aria-label": "how to talk mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to talk MQTT`}</h3>
    <p>{`When you are interested in using the MQTT interface to add your camera to a smarthome system, you are probably already familiar with our `}<a parentName="p" {...{
        "href": "/en/1080p_Series_CGI_List/"
      }}>{`HTTP/S CGI interface`}</a>{` that you can use to send commands to your camera through your web browser.`}</p>
    <p>{`MQTT works much the same way. Every CGI command corresponds to an `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT topic`}</a>{` and every topic can carry specific payloads. For example to activate the first Motion Detection Area on your camera, you can send the following CGI command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`param.cgi?cmd`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`setmdattr`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`-enable`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`\${val}`}</span></code></pre></div>
    <p>{`Where the value (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`val`}</code>{`) can be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` - depending on whether you want to deactivate or activate the detection area.`}</p>
    <p>{`In MQTT doing the same thing looks like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/area1/enable	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"0"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`The topic to switch the area on or off is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/area1/enable`}</code>{` and you have to send either a value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` to switch it.`}</p>
    <p>{`You can find an overview over `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`all available MQTT topics and their Payload here`}</a>{`.`}</p>
    <h3 {...{
      "id": "syntax",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#syntax",
        "aria-label": "syntax permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Syntax`}</h3>
    <p>{`In order to send an MQTT command to the camera, an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/`}</code>{` must first be prepended. To address exactly the right camera you now have 3 options. Either you use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/local/`}</code>{` to address the camera on which the MQTT Broker is running. Or you can name a specific camera by its `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/System/Info/"
      }}>{`(LAN!) MAC address`}</a>{`. For example, if the LAN MAC of your camera is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10:D1:DC:21:8F:96`}</code>{`, then the MQTT ID of this camera is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10D1DC218F96`}</code>{` and the MQTT Topic must contain `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/10D1DC218F96/`}</code>{` to address the camera. And last but not least you have the possibility to address all cameras in the MQTT network at once via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/all/`}</code>{`:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/alarm/area1/enable`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/10D1DC218F96/alarm/area1/enable`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/all/alarm/area1/enable`}</code></li>
    </ul>
    <p>{`After you have updated such a command topic, your camera will return a status topic to tell you that everything worked:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/alarm/area1/enable`}</code>{` `}<strong parentName="li">{`Command Topic`}</strong></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`instar/local/status/alarm/area1/enable`}</code>{` `}<strong parentName="li">{`Status Topic`}</strong></li>
    </ul>
    <p>{`More information can be found in our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
      }}>{`MQTT Topics and Payloads Overview`}</a>{`.`}</p>
    <h3 {...{
      "id": "what-software-can-i-use-this-with",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-software-can-i-use-this-with",
        "aria-label": "what software can i use this with permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What Software can I use this with?`}</h3>
    <p>{`We are collecting an ever growing `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#software"
      }}>{`list of software`}</a>{` that is compatible with the MQTT protocol. An easy way to get started are Dashboard and Testing applications that can be installed on your Android phone or under Windows or macOS:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/"
        }}>{`Android Apps for MQTT`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
        }}>{`Windows and macOS Apps for MQTT`}</a></li>
    </ol>
    <p>{`Both Android apps are simple dashboards that allow you to control your camera through the MQTT interface. In case of the desktop apps, they are used to test your connection to your camera, record interactions between your camera and client application and to debug your MQTT network.`}</p>
    <p>{`For compatible `}<strong parentName="p">{`Smarthome Systems`}</strong>{` please check out our guides for :`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/"
        }}>{`Home Assistant`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/OpenHAB2/"
        }}>{`OpenHAB 2`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/"
        }}>{`Athom Homey`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Android_Apps/"
        }}>{`Android Apps`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
        }}>{`Windows & macOS Apps`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
        }}>{`Node-RED`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Homematic_CCU3_and_RedMatic/"
        }}>{`Homematic`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Loxone/"
        }}>{`Loxone`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/homee/"
        }}>{`homee`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/ioBroker/"
        }}>{`IOBroker`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/FHEM_House_Automation/"
        }}>{`FHEM`}</a></li>
    </ol>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/"
        }}>{`Testing the HTTP and MQTT Alarm Server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Autorefresh_Node-RED/"
        }}>{`Auto-Refreshing MQTT Topics in Node-RED`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Websocket_Client/"
        }}>{`INSTAR MQTTv5 Websocket Client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTT_System_Update/"
        }}>{`Auslösen eines System Updates über MQTT`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/biOs_MQTT_Stream_Deck/"
        }}>{`Stream Deck biOs MQTT`}</a></li>
    </ul>
    <p>{`And more to follow...`}</p>
    <p>{`Please read our FAQ if you want to use a `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Self_Signed_SSL_Certificate/"
      }}>{`self-signed SSL certificate for the INSTAR MQTT-Broker`}</a>{`.`}</p>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      